.HomeSkills {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.tree-container {
  width: 80%;
  max-width: 800px;
}

.timeline {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.timeline-content {
  position: relative;
  width: 50%;
  flex: 1;
  padding: 20px;
  border-radius: 5px;
  padding: 1.75rem;
  transition: 0.4s ease;
  overflow-wrap: break-word !important;
  margin: 1rem;
  margin-bottom: 270px;
  border-radius: 6px;
}
.timeline-content.odd {
  left: -10%;
}
.timeline-content.even {
  left: 57%;
}

.timeline-middle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 3px;
  height: 161%;
  background-image: linear-gradient(45deg, #52f221, #40e9a3, #23508a);
  transform: translateX(-50%);
  z-index: -1;
}

@media only screen and (max-width: 992px) {
  .timeline-middle { height: 175%; }
  .timeline-content { margin-bottom: 100px; width: 60%; }
  .timeline-content.even { left: 46%; }
}
@media only screen and (max-width: 550px) {
  .timeline-middle { height: 125%; opacity: 0.1; }
  .timeline-content { margin-bottom: 35px; width: 80% }
  .timeline-content.even { left: 27%; }

}



/* .HomeSkills {
  display: flex;
  justify-content: center;
  margin-top: -20%;
  align-items: center;
  height: 100vh;
  margin-bottom: 15%;
}

.HomeSkills.sticky {
  position: sticky;
  top: 0;
}
  
.tree-container {
  position: relative;
  width: 400px; /* Adjust the width based on your design 
}

.tree {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
  
.branch {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 150px; /* Adjust the spacing between branches 
}

.branch::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  background-color: #8B4513; /* Brown color for branches 
  transform: translateX(-2px);
}

.skill {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  opacity: 0; /* Hidden by default 
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.branch.left::before {
  transform-origin: top right;
}

.branch.right::before {
  transform-origin: top left;
}

.branch.grow.left .skill {
  opacity: 1;
  transform: translateY(0) translateX(-50%);
}

.branch.grow.right .skill {
  opacity: 1;
  transform: translateY(0) translateX(50%);
}

@media only screen and (max-width: 400px) {
  .HomeSkills {
    margin-top: -150%;
    margin-bottom: 125%;
  }
}
   */

