#review {
  width: 75%;
  margin: 0 auto;
}

.star-rating {
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
.review-font {
  font-family: 'Montserrat', sans-serif;
}
  
.fas.fa-star.hovered,
.fas.fa-star.hovered ~ .fas.fa-star {
  color: #62ad81;
}

.star {
  color: #414141;
  font-size: 19px;
  margin-right: -1px;
  display: inline-block;
  position: relative; /* Adjust the spacing between stars as needed */
}
.star i {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.full-star {
  margin-right: 4.5px; /* Adjust this value to align full stars properly */
}


  



