.contact {
    font-family: 'Montserrat', sans-serif;
    margin: 5% 0 7%;
}
.contact-left h2 span {
    font-family: 'Alfa Slab One', serif;
    -webkit-text-stroke-width: 1px;
}
.contact-left {
    padding-top: 12%;
    font-weight: 600;
}
.contact-left h2, h3 {
    font-weight: 200;
}