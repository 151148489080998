@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');


.HomeReviews {
    font-family: 'Montserrat', sans-serif;
}

.reviews-header {
  font-weight: 400;
  position: relative;
  top: 60px;
}

.main-review p {
  padding: 20% 10px 10% 10px;
  width: 100%;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.shadow-effect {
  width: 85%;
  margin: 0 10px;
  text-align: center;
}
#shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 200;
}
.img-circle {
  border-radius: 50%;
  vertical-align: middle;
  max-width: 90px;
  min-height: 90px;
  transform-style: preserve-3d;
  margin: 0 auto 17px;
}
#customer-testimonoals {
  margin-top: 6%;
}
.testimonial-name {
  position: relative;
  display: inline-block;
  justify-content: center;
  width: 85%;
  top: 60px;
  background: linear-gradient(100deg, #62ad81, #53926d);
  padding: 9px 35px;
  text-align: center;
  color: #fff;
}
.testimonial-name h5 {
  margin-bottom: 0;
}

#customer-testimonoals .item {
  text-align: center;
  padding: 50px;
  margin-bottom: 0px;
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 0.3s ease-in-out;
}
#customer-testimonoals .owl-item.active.center .item {
  opacity: 1;
  transform: scale3d(1.0, 1.0, 1);
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #56423D;
  transform: translate3d(0px, -50%, 0px) scale(0.8);
}
.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.owl-carousel .owl-dots .owl-dot{ 
  display: inline-block;
}
.owl-carousel .owl-dots .owl-dot span{ 
  /* background: #eaa400; */
  background: #56423D;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
} 

.hover-stars {
  color: #62ad81
}