.style-switcher {
  position: relative;
  z-index: 101;
  border-radius: 5px;
  transition: all 0.3s ease;
  z-index: 1000;
}

.toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.color-container {
  display: none;
  position: absolute;
  padding: 15px;
  top: 50px;
  background-color: #e4e7ec;
  border-radius: 10%;
  transition: display 1s;
}
.color-container.open {
  display: block;
  right: -150%;
}

.style-switcher .s-icon i {
  line-height: 40px;
}
.style-switcher .style-switcher-toggler {
  top: 0;
}
.style-switcher .day-night {
  top: 55px;
}

.style-switcher h4 {
  margin: 0 0 10px;
  /* color: var(--text-black-700); */
  font-size: 16px;
  text-transform: capitalize;
}

.style-switcher .colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.style-switcher .color-1 {
    background: #ec1839;
}
.style-switcher .color-2 {
    background: #f021b2;
}
.style-switcher .color-3 {
    background: #37b182;
}
.style-switcher .color-4 {
    background: #1854b4;
}

.toggle-label {
  position: relative;
  display: block;
  width: 135px;
  height: 45px;
  border-radius: 100px;
  background-color: #f1e3d7;
  overflow: hidden;
  cursor: pointer;
}
.toggle-checkbox[type=checkbox]:checked + .toggle-label {
  background-color: #151829; 
}

.toggle-label:before, .toggle-label:after {
  display: block;
  position: absolute;
  content: "";
  width: 54px;
  height: 54px;
  border-radius: 50%;
  top: 9px;
  left: 9px;
  transition: 0.5s ease;
}
.toggle-label:before {
  background-color: #ffa41b;
}
.toggle-label:after {
  background-color: #151829;
  left: -44px;
  transform: scale(0.00001);
}

.toggle-checkbox[type=checkbox] {
  display: none;
}
.toggle-checkbox[type=checkbox]:checked + .toggle-label:before {
  background-color: #FFF;
  transform: translateX(60px);
}
.toggle-checkbox[type=checkbox]:checked + .toggle-label:after {
  transform: translateX(90px) scale(1);
}

@media only screen and (max-width: 992px) {
  .toggle-label {
    width: 120px; /* Adjusted width */
    height: 30px; /* Adjusted height */
  }
  .toggle-label:before, .toggle-label:after {
    width: 30px; /* Adjusted width */
    height: 30px; /* Adjusted height */
    top: 5px; /* Adjusted top */
    left: 5px;
  }
  .toggle-label:after {
    left: -20px; 
  }
  
  .color-container.open {
    width: 175px;
    right: 5%
  }
  .style-switcher {
    right: -25%;
  }

}

@media only screen and (max-width: 700px) {
  .style-switcher {
    right: -20%;
  }
  .color-container.open {
    right: -175%
  }
}
@media only screen and (max-width: 600px) {
  .style-switcher {
    right: -15%;
  }
  .color-container.open {
    right: -145%
  }
}
@media only screen and (max-width: 500px) {
  .style-switcher {
    right: 2.5%;
  }
  .color-container.open {
    right: -150%
  }
}
@media only screen and (max-width: 330px) {
  .style-switcher {
    right: 2%;
  }
}





