@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600;700;900&display=swap');

/* homePortfolio Header */
.parallax-text {
    margin: 0;
    position: relative;
    white-space: nowrap;
}
.heading-text-featured {
    font-family: 'Montserrat', sans-serif;
    font-size: 5rem;
    font-weight: 400;
    right: 0;
    text-align: right;
}
.heading-text-work {
    font-family: 'Alfa Slab One', serif;
    font-size: 8rem;
    font-style: italic;
    text-align: left;
    top: -57px;
    width: 10%;
}

@media only screen and (max-width: 992px) {
    .portfolioHeader {
        padding-left: 5%;
    }
    .heading-text-featured { 
        text-align: left;
        font-size: 2.75rem;
    }
    .heading-text-work {
        padding-left: 6%;
        font-size: 5rem;
        top: -32px;
    }
}

/* homePortfolio project section */
.project {
    display: flex;
    height: 750px;
    max-height: 75vw;
    overflow: hidden;
    opacity: 1;
    margin-bottom: 3%;
}
.project:nth-child(odd) {
    justify-content: flex-start;
}
.project:nth-child(even) {
    justify-content: flex-start;
    flex-direction: row-reverse;
}

.project-image-container {
    z-index: 5;
    width: 68vw;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
}
.project-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: absolute;
    top: 5%;
    left: 0%;
}
.project-title {
    position: absolute;
    font-family: 'Titillium Web', sans-serif;
    color: #ddd;
    text-transform: uppercase;
    letter-spacing: 11px;
    font-size: 4.9vw;
    font-weight: 900;
    line-height: 1em;
    top: 0;
    padding-left: 27%;
}
.project:nth-child(even) > div > .project-title {
    padding-left: 10%;
}
.project:nth-child(odd) > div > .project-title {
    text-align: right;
}
.project-link {
    z-index: 15;
    opacity: 0.6;
    position: relative;
    height: fit-content;
    transform: rotate(90deg);
    top: 80%;
    left: -2%;
}
.project-link a {
    text-decoration: none;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25em;
}
.project-link a .underline::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; 
    bottom: 0;
    left: 0;
    background-color: #62ad81;
    transition: transform 1s ease;
    transform-origin: left; 
    transform: scaleX(0); 
  }

  .project-link a:hover .underline::before {
    transform: scaleX(1); 
  }
  .project:nth-child(even) .project-link a .underline::before {
    transform-origin: right; 
    transform: scaleX(0); 
  }

  .project:nth-child(even) .project-link a:hover .underline::before {
    transform: scaleX(1); 
  }
.project:nth-child(even) .project-link {
    transform: rotate(-90deg);
    right: -2%;
    left: unset;
}

@media only screen and (max-width: 992px) {
    .project-link a { font-size: 1.5em; }
}
@media only screen and (max-width: 550px) {
    .project-link { top: 75%; left: -10% }
    .project:nth-child(even) .project-link { right: -10%; }
    .project-link a { font-size: 1.25em; }

}