.HomeSummary.fixed {
  position: sticky;
  top: 0%; /* Adjust as needed */
  width: 100%; /* Adjust as needed */
}

@media only screen and (max-width: 992px) { 
  .HomeSummary.fixed {
    position: relative;
  }
  #hover-container {
    flex-direction: column;
  }
  .left-saloon {
    display: flex;
    justify-content: center;
  }
}


