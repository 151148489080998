@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

:root {
    --skin-color: #62ad81;
    --bg-black-900: #F7F7F9;
    --bg-black-100: #fdf9ff;
    --bg-black-75: #ebebeb;
    --bg-black-50: #f1f1f1;
    --text-black-900: #1B1B1B;
    --text-black-700: #DDDDDD;
    --bg-input: #ddd;
    --overlay: rgba(197, 197, 197, 0.35);
    --overlay-light: rgba(197, 197, 197, 0.0);
    --grunge: url(../../../public/media/grunge.png);
}

body.dark {
    --bg-black-900: #151515;
    --bg-black-100: #1b1b1b;
    --bg-black-75: #232323;
    --bg-black-50: #393939;
    --text-black-900: #f3f3f3;
    --text-black-700: #e9e9e9;
    --overlay: rgba(0, 0, 0, 0.35);
    --overlay-light: rgba(0, 0, 0, 0.0);
    --grunge: url(../../../public/media/grunge-dark.png);
}

body {
    background-color: var(--bg-black-100) !important;
    background: var(--grunge) no-repeat;
    background-attachment: fixed;
}

.white-text {
    color: var(--text-black-900);
}

.dark-text {
    color: var(--bg-black-900);
}

span.primary-text {
    color: var(--skin-color);
}
.white-black-bg {
    background-color: var(--bg-black-100);
}
.primary-text {
    color: var(--skin-color);
}
.secondary-text {
    color: var(--text-black-700)
}

.primary {
    background-color: var(--skin-color);
}
.secondary {
    background-color: var(--text-black-700);
}
.black-white-bg {
    background-color: var(--text-black-900);
}
.typed {
    color: var(--skin-color);
}

/* HEADER */
.header.scrolled > .logo > a {
    color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    transition: color 1s; /* Add a smooth transition effect */
}


.navBurger > div {
    z-index: 1000;
    width: 100%;
    height: 3px;
    margin-top: 25%;
    background-color: var(--text-black-900);
    transition: transform 0.4s ease;
}


ul.main-menu {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    list-style-type: decimal-leading-zero;
    list-style-position: inside;
    font-size: 2.75rem;
    width: 70%;
    padding: 10% 0;
    margin-right: 2%;
    border-right: 1px solid var(--skin-color);
}

.title-block {
    color: var(--skin-color);
    font-weight: 400;
    letter-spacing: 2px;
}

@media only screen and (max-width: 991px) {
    body { background-position: bottom; }

    .dropdown-nav.open {
      flex-direction: column;
    }
    ul.main-menu {
      align-items: center;
      list-style-type: none;
      margin-right: 0;
      border-bottom: 1px solid var(--skin-color);
      border-right: none;
    }
    .social-menu {
      font-size: 1.5rem;
    }
    .footer-link {
        padding-left: 5%;
    }

    .primary-text > a {
        padding-left: 0% !important;
    }
}

.outline-header {
    font-family: 'Titillium Web', sans-serif;
    color: var(--text-black-900);
    text-transform: uppercase;
    letter-spacing: 11px;
    font-weight: 900;
    line-height: 1em;
}
  
@media only screen and (max-width: 550px) {
    ul.main-menu {
      text-align: center;
      font-size: 2rem;
    }
    .social-menu {
        font-size: 1rem;
      }
}

/* Home */
.hero h2 {
    font-family: 'Titillium Web', sans-serif;
}
    /* HomePortfolio */
    .heading-text-work {
        color: #ffffff00;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: var(--text-black-900);
    }

    .project-title.front {
        color: #ffffff00;
    }
    .project-title.back {
        color: var(--text-black-900);
        z-index: -1;
    }
    .project-title.front {
        -webkit-text-stroke: 1px var(--text-black-900);
    }

    @media only screen and (min-width: 992px) {
        .project-title.front {
            -webkit-text-stroke: 2px var(--text-black-900);
        }
    }
    .project-image::before, .pro-bg-image::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--overlay);
        transition: background-color 0.3s ease;
    }

    .pro-bg-image::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--overlay);
        transition: background-color 1s ease;
    }

    .pro-bg-image:hover::before {
        background-color: var(--overlay-light);
    }


    /* HomeSummary */
    .HomeSummary {
        position: relative;
        z-index: -1; /* Lower z-index */
    }
 
    /* HomeSkills */
    .HomeSkills {
        position: relative;
        z-index: -1; /* Set a higher z-index */
    }
    .timeline-content {
        background: var(--overlay);
        color: var(--text-black-900);
        box-shadow: 5px 5px 10px var(--overlay), -5px -5px 10px var(--overlay);
      }

    /* HomeReviews */
    .main-review p {
        background: var(--bg-black-50);
    }
    .shadow-effect {
        color: var(--text-black-900);
    }
    .star-rating {
        color: #7a7a7a;
    }

/* About */
.about-header { font-size: 2.5rem; }
#service-section > h2 { font-size: 5rem; }

@media only screen and (min-width: 992px) {
    .outline-header, .project-name {
        -webkit-text-stroke: 2px var(--text-black-900);
        color: rgba(0, 0, 0, 0);
        font-size: 4rem;
    }
    .about-header > span { -webkit-text-stroke: 0px; }
}
@media only screen and (max-width: 550px) {
    .about-header { font-size: 2rem; }
    #service-section > h2 { font-size: 1.5rem; }
    .about-header > span { 
        font-size: 1.15rem; 
        letter-spacing: 5px;
        position: relative;
        top: -14px;
     }
}

.about-button, .contact-button {
    text-transform: uppercase;
    color: var(--bg-black-100);
    border: 0;
    padding: 20px 48px;
    transform: translateY(calc(var(--y, 0) * 1%)) scale(var(--scale));
    transition: transform 0.1s;
    position: relative;
  }
  
  .about-button:hover {
    --y: -10;
    --scale: 1.1;
    --border-scale: 1;
    -webkit-box-shadow:0px 0px 103px 10px rgba(92,126,107,0.84);
    -moz-box-shadow: 0px 0px 103px 10px rgba(92,126,107,0.84);
    box-shadow: 0px 0px 103px 10px rgba(92,126,107,0.84);
  }
  
  .about-button:active {
    --y: 5%;
    --scale: 0.9;
    --border-scale: 0.9, 0.8;
  }
  
  .about-button:before {
    content: "";
    position: absolute;
    inset: calc(1rem * -1);
    border: .35rem solid #5c7e6bb0;
    transform: scale(var(--border-scale, 0));
    transition: transform 0.125s;

    
    --angle-one: 105deg;
    --angle-two: 290deg;
    --spread-one: 30deg;
    --spread-two: 40deg;
    --start-one: calc(var(--angle-one) - (var(--spread-one) * 0.5));
    --start-two: calc(var(--angle-two) - (var(--spread-two) * 0.5));
    --end-one: calc(var(--angle-one) + (var(--spread-one) * 0.5));
    --end-two: calc(var(--angle-two) + (var(--spread-two) * 0.5));
    
    mask: conic-gradient(
      transparent 0 var(--start-one),
      white var(--start-one) var(--end-one),
      transparent var(--end-one) var(--start-two),
      white var(--start-two) var(--end-two),
      transparent var(--end-two)
    );
    
    z-index: -1;
  }


/* Contact */
.contact-left h2 span {
    -webkit-text-stroke-color: var(--skin-color);
    -webkit-text-fill-color: transparent;
}
.contact-left {
    background-color: var(--bg-black-75);
    color: var(--text-black-900);
}

input, textarea, input:focus, textarea:focus, input:not(:placeholder-shown), textarea:not(:placeholder-shown) {
    background-color: var(--bg-input) !important;
    color: #1B1B1B !important;
    border-radius: 0;
}

.contact-button {
    background-color: var(--skin-color);
    transition: background-color 1s;
}
.contact-button:hover {
    background-color: #4e8866;
}

/* project.jsx */
.pro-link { color: var(--bg-black-100); }
.label-div, .pro-name-div { background-color: var(--text-black-900); }
.pro-year-txt { color: var(--text-black-900); }



/* Footer */
.footer {
    background: linear-gradient(0deg, var(--bg-black-100) 17%, rgba(0,0,0,0) 100%);
    color: var(--text-black-900);
    position: relative;
    z-index: 950;
}

.header-after {
    background-color: var(--skin-color);
    height: 2px;
    width: 40px;
    margin-bottom: 15px;
}

.footer-link, .primary-text > a {
    text-decoration: none;
    color: var(--text-black-900);
    padding-left: 5%;
    transition: color 0.5s ease;
}

.footer-link:hover, .primary-text > a:hover {
    color: #a1a1a1;
}

/*  Switcher */
.style-switcher .s-icon {
    height: 45px;
    width: 45px;
    text-align: center;
    font-size: 20px;
    color: white;
    border: 1px solid white;
    background: var(--bg-black-100);
    color: var(--text-black-900);
    right: 100%;
    border: 3px solid var(--skin-color);
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 50%;
    
}
.style-switcher .colors span {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 3px solid var(--skin-color);
}

/* General hide display for elements on mobile */
@media only screen and (max-width: 992px) {
    .mobile-hidden { display: none; }
}


/* Loading Component */
.loading-container {
    background-color: var(--text-black-900);
  }




/*  Comments */

.reviews-header {
    color: var(--text-black-900)
}