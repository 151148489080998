
body {
  margin: 0;
  font-family:Helvetica,Sans-serif;
  overflow-x:hidden;
  /* background-color: #1b1b1b; */
}
h1 {
  text-align:center;
}
.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:100vw;
  height:85vh;
  perspective:1px;
  perspective-origin: center center;
  overflow-x:hidden;
  padding-left: 12%;
}

.hero h2 {
  font-size: 3rem;
}
.hero h3 {
  font-size: 5rem;
  -webkit-text-stroke-width: 1px !important;
}
.hero img {
  position: absolute;
  z-index: -500;
  width: 50%;
  left: 38%;
  top: -3%;
}

@media only screen and (max-width: 992px) {
  .hero { padding-left: 8%; }
  .hero img { width: 65%; top: 4%; left: 30%; }
  .hero h2 { font-size: 2.5rem }
  .hero h3 { font-size: 4rem; font-weight: 700; }
}
@media only screen and (max-width: 550px) {
  .hero { padding-left: 5%; }
  .hero img { width: 70%; }
  .hero h2 { font-size: 1.75rem; letter-spacing: 6px; }
  .hero h3 { font-size: 2rem; font-weight: 600; letter-spacing: 12px; }
}