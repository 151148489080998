.about-container {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 1.15rem;
}

.about-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.about-main a {
    align-self: center;
}

