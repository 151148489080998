.project-name {
    font-family: 'Titillium Web', sans-serif;
    text-transform: uppercase;
    letter-spacing: 11px;
    font-size: 4.9vw;
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 5%;
}

.prheader {
    font-size: 1.25rem;
}

.prcontent {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 1.15rem;
}
.pr-container {
    opacity: 1;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    position: relative;
}

@media only screen and (max-width: 992px) {
    .pr-container { flex-direction: column; }
    .pro-link { width: 100% !important; height: 40vw !important; margin-top: 43px !important; }
}


.hide {
    opacity: 0;
}

/* Hide elements initially */
.project-row {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
  }
  
/* Show elements when the visible class is added */
.project-row.visible {
    opacity: 1;
    transform: translateY(0);
}


.pro-link {
    width: 45%;
    height: 26vw;
    max-height: 720px;
    justify-content: flex-start;
    align-items: flex-end;
    margin-right: 2%;
    text-decoration: none;
    display: flex;
    position: relative;
}

.pro-link:nth-child(2n) {
    margin-top: 130px;
}

.label-div {
    z-index: 10;
    height: 35px;
    margin: 43px;
    padding: 5px 26px;
    position: relative;
}

@media only screen and (max-width: 550px) {
    .label-div { font-size:0.75rem !important; padding: 5px !important }
}

.pro-name-div {
    z-index: 5;
    height: 56px;
    margin-left: 25px;
    margin-right: 25px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    top: auto;
    bottom: 90px;
    left: 0%;
    right: auto;
}

.pro-heading {
    letter-spacing: .1px;
    text-transform: uppercase;
    margin-top: 15px;
    font-size: 30px;
    font-weight: 500;
    line-height: 1em;
}

@media only screen and (max-width: 1400px) {
    .pro-heading { font-size: 25px; }
}
@media only screen and (max-width: 1200px) {
    .pro-heading { font-size: 20px; overflow: hidden; }
    .label-div { font-size: 15px; }
}

.pro-bg-image-wrap {
    width: 100%;
    height: 100%;
    transform-origin: 50% 0;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: hidden;
}

.pro-bg-image {
    z-index: 1;
    width: 100%;
    height: 100%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.pro-year-txt {
    display: inline-block;
    z-index: 10;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
    position: absolute;
    top: auto;
    bottom: 43px;
    left: 95%;
    right: -27px;
    transform: rotate(90deg);
}