#service-section {
    min-height: 700px;
    width: 100%;
    background-color: var(--bg-black-75);
    color: var(--text-black-900);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    flex-direction: column;
    padding: 15px 0;
}

.subheader {
    position: relative;
    width: 40%;
    font-size: 1rem;
    text-align: center;
    line-height: 1.2rem;
    margin-bottom: 40px;
    text-transform: uppercase;
    top: -12px;
    letter-spacing: 2px;
}

@media only screen and (max-width: 992px) {
    .subheader { width: 100%; }
}
@media only screen and (max-width: 550px) {
    .subheader { top: 0px; }
    .card-service::before { overflow: hidden; }
}

.cards {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 25px;
    z-index: 9;
    margin-top: 5px;
    padding: 0 2%;
    justify-items: center;
}

.cards .card-services {
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 8%;
    background-color: var(--skin-color);
    border-radius: 5px;
    position: relative;
}

.cards .card-services::before {
    position: absolute;
    content: " ";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 5px;
    background: #227052;
    transition: 0.5s;
    transform: rotate(0deg);
    z-index: -1;
}

.cards .card-services:hover::before {
    transform: rotate(10deg);
}

.cards .card-services i {
    font-size: 2.5rem;
    color: #123b2c;
    margin-top: 15px;
    margin-bottom: 10px;
}

.cards .card-service h3 {
    font-size: 1.8rem;
    color: var(--text-black-900);
    margin-bottom: 10px;
}

.cards .card-service .content p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.05rem;
    margin-bottom: 10px;
    font-weight: 500;
}

@media(max-width:1100px) {
    #service h2 {
        margin-top: 30px;
    }
    .cards {
        grid-template-columns: repeat(1, 1fr);
        width: 90%;
        padding: 0;
    }
    #service-section #paragraph {
        width: 80%;
    }
}

@media(max-width:900px) {
    .cards .card-service {
        height: 300px;
        width: 400px;
        margin: 20px;
    }
}