/* Style for the footer container */
.footer {
    padding: 20px;
    text-transform: uppercase;
}
.footer-logo, .footer-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-logo img {
    height: 150px;
}

.footer-text {
    font-family: 'Play', sans-serif;
}
.footer-header {
    font-size: 20px;
    letter-spacing: 2px;
    position: relative;
    text-transform: uppercase;
}


.footer-border { border-top: 1px solid #62ad8124; }
.footer-main {
    margin-bottom: 25px;
}
.footer-3 {
    margin-top: 25px !important;
    font-size: 14px;
    letter-spacing: 2.67px;
}



@media (max-width: 992px) {
    .footer-main {
        background-image: url('../../../../public/media/logo-footer.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        position: relative;
        justify-content: space-between;
    }
    .footer-header {
        margin-top: 15px;
    }
    .header-after {
        margin-bottom: 4px;
    }
    .footer-logo {
        display: none;
    }
}
  
@media (max-width: 520px) {
    .footer-main {
        background-size: 60%;
    }
    .footer-content {
        padding-left: 15% !important;
    }
}