@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  font-family: 'Montserrat';
}
.logo {
  color: white;
  font-size: 24px;
  margin-left: 20px;
}

.menu-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.menu-icon {
  z-index: 1001;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
}
.navToggler{
  cursor: pointer;
  z-index: 1001;
  width: 50px;
  height: 50px;
  opacity: 0;
}
.navBurger > div {
  z-index: 1000;
  width: 100%;
  height: 3px;
  margin-top: 25%;
  background-color: #fff;
  transition: transform 0.4s ease;
}
.navBurger > div:nth-child(2) {
  width: 75%;
}
.navBurger > div::after {
  top: 10px;
}
/* Rotate hamburger to X when isNavOpen is true */
.menu-container .navBurger.open > div:nth-child(1) {
  transform: rotate(45deg) translate(5px, 7px);
}
.menu-container .navBurger.open > div:nth-child(2) {
  transform: rotate(0) scaleX(0);
}
.menu-container .navBurger.open > div:nth-child(3) {
  transform: rotate(-45deg) translate(8px, -9px);
}

.dropdown-nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 998;
  left: -100%; /* Initially hide the menu off the screen */
  transition: left 0.5s; /* Add a transition for smooth slide-in animation */
}
.dropdown-nav.open {
  left: 0; /* Show the menu by moving it to the left edge of the screen */
}

.socials-list {
  padding-left: 15px;
}
.dropdown-nav a {
 /*  color: white; */
  text-decoration: none;
  margin: 10px;
}
.menu-icon:hover .button-text {
  content: "Close";
}

/* .fa-light {
  font-size: 2.33333em;
  color: #62ad81;
} */



    